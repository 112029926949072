import { useEffect } from 'react'
import styled from 'styled-components'
import Logo from './Components/logo'
import Cards from './Components/cards.js'
import { IS_DEV } from './env.js'

const Overflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.56rem;
  max-width: 1280px;
  width: 100%;
`

const Main = () => {  
  useEffect(() => {
    if (!IS_DEV && window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace(/https:/)
    }
  }, [])

  return (
    <>
      <Logo />
      <Overflow>
        <Cards />
      </Overflow>
    </>
  )
}

export default Main