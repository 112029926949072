import React from 'react'
import ReactDOM from 'react-dom/client'
import styled from 'styled-components'
import './index.css'
import { URL } from './env.js'
import king from './assets/king.svg'
import Main from './Main'

const Overflow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 10px;
  box-sizing: border-box;
`

const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: auto;

  @media (max-width: 670px) {
    width: 100%;
  }
`

const Image = styled.div`
  background-image: url(${URL}/assets/4.jpg);
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  border: 0.0625rem solid #d4d4d4;
`

const Icon = styled.img`
  
`

const ImageBody = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <ImageBody>
      <Icon 
        src={king} 
        style={{
          width: (s => `${s - (s / 1.1)}px`)(window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth)
        }}   
      />
      <Image 
        style={{ 
          width: (s => `${s - (s / 3)}px`)(window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth),
          height: (s => `${s - (s / 3)}px`)(window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth)
        }}   
      />
    </ImageBody>
    {/*<Overflow>
      <Body>
        <Main />
      </Body>
    </Overflow>*/}
  </React.StrictMode>
)