import styled from 'styled-components'
import { motion } from 'framer-motion'
import heart from './../assets/heart.svg'
import man from './../assets/man.svg'
import girl from './../assets/girl.svg'
import { useEffect, useState } from 'react'
import { URL } from './../env.js'
import axios from 'axios'

const Body = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
`

const CardImage = styled.div`
  border-radius: 0.94rem;
  width: 27.5rem;
  height: 27.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  max-width: calc(100% - 20px);
  min-width: 200px;
  position: relative;

  @media (max-width: 670px) {
    width: 100vw;
    height: calc(100vw - 20px);
    box-sizing: border-box;
    max-width: calc(100vw - 20px);
    min-width: 200px;
  }
`

const CardInfoOverflow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 0.24rem;
  position: absolute;
  bottom: 10px;
  width: 100%;
`

const ButtonBody = styled(motion.div)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.62rem;
  border: 0.0625rem solid #d4d4d4;
  border-radius: 0.94rem 0.94rem 0.94rem 0.94rem;
  height: 3rem;
  background: #fff;
  padding: 0rem 1rem;
  margin-top: 1.5625rem;
`

const ButtonOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.06rem;
`

const Icon = styled.img`
  width: 1.9rem;
  height: 1.9rem;
`

const ButtonTextOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.12rem;
`

const BigText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
  color: #000;
`

const Card = ({ idHash, number }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`${URL}/card?id=${idHash}`)
      .then(({ data }) => setData(data))
  }, [idHash])

  const handleVote = async () => {
    try {
      const { data: ok } = await axios.get(`${URL}/status`)
      if (ok) {
        window.location.href = `${URL}/bot?data=${idHash}`
      }
    } catch (e) {}
  }

  return data ? (
    <CardImage src={`${URL}/assets/${data.image}.jpg`}>
      <CardInfoOverflow style={{ top: '0px', left: '1.4rem', justifyContent: 'flex-start' }}>
        <ButtonBody 
          style={{ 
            background: number === 1 
                          ? '#feea87'
                          : number === 2 
                              ? '#cfcfcf'
                              : number === 3
                                  ? '#ffbb79'
                                  : '#fff',
            borderRadius: '100000px', 
            padding: '0px', 
            width: '3rem'
          }}>
          <ButtonOverflow>
            <ButtonTextOverflow>
              <BigText>#{number || 100}</BigText>
            </ButtonTextOverflow>
          </ButtonOverflow>
        </ButtonBody>
      </CardInfoOverflow>
      <CardInfoOverflow>
        <ButtonBody style={{ borderRadius: '0.94rem 0rem 0rem 0.94rem' }} whileTap={{ scale: 0.9 }} onClick={() => handleVote()}>
          <ButtonOverflow>
            <ButtonTextOverflow>
              <BigText>{data.votes || 0} голосов</BigText>
            </ButtonTextOverflow>
          </ButtonOverflow>
        </ButtonBody>
        <ButtonBody style={{ borderRadius: '0rem 0.94rem 0.94rem 0rem' }} whileTap={{ scale: 0.9 }} onClick={() => handleVote()}>
          <ButtonOverflow>
            <Icon draggable={false} src={heart} />
          </ButtonOverflow>
        </ButtonBody>
      </CardInfoOverflow>
    </CardImage>
  ) : null
}

const Cards = () => {
  const [state, setState] = useState(true)
      , [idsHash, setIdsHash] = useState([])

  useEffect(() => {
    axios.get(`${URL}/cards?isAltushka=${JSON.stringify(state)}`)
      .then(({ data }) => setIdsHash(data))
  }, [state])

  return (
    <Body>
      <CardInfoOverflow style={{ position: 'static' }}>
        <ButtonBody style={{ borderRadius: '0.94rem 0rem 0rem 0.94rem' }} whileTap={{ scale: 0.9 }} onClick={() => setState(true)}>
          <ButtonOverflow style={{ gap: '0.26rem' }}>
            <Icon draggable={false} src={girl} />
            <BigText>Альтушки</BigText>
          </ButtonOverflow>
        </ButtonBody>
        <ButtonBody style={{ borderRadius: '0rem 0.94rem 0.94rem 0rem' }} whileTap={{ scale: 0.9 }} onClick={() => setState(false)}>
          <ButtonOverflow style={{ gap: '0.26rem' }}>
            <BigText>Инцелы</BigText>
            <Icon draggable={false} src={man} />
          </ButtonOverflow>
        </ButtonBody>
      </CardInfoOverflow>
      {
        idsHash.map((idHash, key) => (
          <Card idHash={idHash} number={key+1} key={key} />
        ))
      }
    </Body>
  )
}

export default Cards