import styled from 'styled-components'
import { motion } from 'framer-motion'
import logo from './../assets/logo.svg'

const Overflow = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.62rem;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
`

const LogoOverflow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1.12rem;
`

const LogoCircle = styled.div`
  border-radius: 0.94rem;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: #fff;
`

const LogoText = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.62rem;
  line-height: 104%;
  letter-spacing: -0.03em;
  color: #000;
  position: relative;
`

const T = styled.div`
  position: relative;
  z-index: 999;
  color: #fff;
`

const LogoIcon = styled.div`
  background-image: url(${logo});
  background-size: cover;
  width: 5.695rem;
  height: 5.454rem;
`

const Logo = () => {
  return (
    <Overflow>
      <LogoOverflow>
        <LogoCircle>
          <motion.div
            style={{ outline: 'none' }} 
          >
            <LogoIcon />
          </motion.div>
        </LogoCircle>
        <LogoText>
          <T>ЛУЧШАЯ</T>
          <T>АЛЬТУШКА</T>
          <T>И ИНЦЕЛ</T>
        </LogoText>
      </LogoOverflow>
    </Overflow>
  )
}

export default Logo